import React from "react";
import Layout from "../components/layout";
import HomePage from "../modules/home-page/HomePage";

const IndexPage = () => (
  <Layout>
    <HomePage />
  </Layout>
);

export default IndexPage;
