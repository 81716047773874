import React from "react";
import PropTypes from "prop-types";
import "./HomePage.scss";

const HomePage = props => {
  return (
    <>
      <div className="home-page_main-header">
        Under development. <br />
        Please see <a href="https://www.shaneschmaltz.com">https://www.shaneschmaltz.com</a> at this
        time
      </div>
    </>
  );
};

HomePage.propTypes = {};

export default HomePage;
